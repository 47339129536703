body {
  height: 100vh;
  margin: 0;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.footer {
  margin-top: auto;
}
