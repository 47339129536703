
.error_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 24px;
  margin-top: 3rem;
  margin-bottom: 5rem;
}

.error_message {
  color:darkred;
}